

  .download-section .app-shape-2 {
    width: 560px;
    height: 560px;
    border: 1px solid rgba(255,255,255,0.15);
    border-radius: 50%;
    left: 31%;
    top: 60px;
    position: absolute;
  }

  .download-section .app-shape-3 {
    width: 560px;
    height: 560px;
    border: 1px solid rgba(255,255,255,0.15);
    border-radius: 50%;
    right: 15%;
    bottom: 70%;
    position: absolute;
  }

  .download-section .app-shape-4 {
    width: 560px;
    height: 560px;
    border: 1px solid rgba(255,255,255,0.15);
    border-radius: 50%;
    right: -243px;
    top: -50px;
    position: absolute;
  }

  .download-app-wrapper .shape-1 {
    position: absolute;
    top: 55%;
    left: 61%;
  }
 
  .download-app-btn .app-btn li a {
    width: 180px;
    height: 63px;
    display: inline-block;
    background-color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 0 5px rgba(255,255,255,0.15);
    box-shadow: 0 0 0 5px rgba(255,255,255,0.15);
  }